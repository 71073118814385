.App {
  text-align: center;
  background: #474747;
  overflow-y: scroll;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* ::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-image: rgba(0, 255, 255, 0.144);
}

::-webkit-scrollbar-thumb {
  background-image: linear-gradient(rgb(11, 94, 94), rgb(9, 43, 37));
  background: #155349;
  border-radius: 1px;
} */

hr.style-two {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0));
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

p,span {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}

.MuiButton{
  font-family: 'saira semi condensed' !important ;
  /* color: #148e7a ; */
  border-radius: 2 !important ;
  border: solid !important ;
}


/* ::-webkit-scrollbar-thumb:hover{
  width: 25px;
} */

/* .mbutton:hover{
  background-image: beige;
  color: white;
} */