@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --vh: 100%; /* Update this dynamically using JavaScript if needed */
}

body {
  margin: 0px;
  padding: 0px;
  font-family:  'Saira Semi Condensed',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  font-family: 'Saira Semi Condensed', sans-serif;
  width: 100vw;
  height: 100vh;
}

* {
  font-family: 'Saira Semi Condensed' !important;
}

/* :root{
  width: '80vw';
  height: '80vh';
} */


.buttonexotic {
  /* transition-duration: 1s; */
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  /* box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); */
  opacity: 0.6;
}

.buttonexotic:hover {
  /* background-color: 'cyan';  */
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  opacity: 1;
  /* color: white; */
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19); */
}

.MuiButton{
  font-family: 'saira semi condensed' !important ;
  color: #148e7a !important ;
  border-radius: 0 !important ;
  border: solid !important ;
}