.background {
  background: linear-gradient(#141414, #193133);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* overflow-y: hidden; */
}

.scrollText {
  font-size: 1.5rem;
}

.leftcard {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  font-size: 1.25rem;
  height: 100vh;
  width: 50%;
  text-align: center;
  border-radius: 0px;
}

.rightcard {
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  height: 85vh;
  width: 80vw;
  text-align: center;
  border-radius: 0px;
  color: black;


}

.blur{
  background-size: cover;
  backdrop-filter: blur(5px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -ms-filter: blur(4px);
  -o-filter: blur(4px);
  filter: blur(4px);
}

.parallax {
  /* padding-top: 10vh; */
  margin-right: 10vw;
  /* background: rgba(35, 41, 41, 0.438); */
  /* background: linear-gradient(rgba(13, 187, 187, 0.658), rgba(86, 117, 117, 0.705), rgba(64, 115, 128, 0.164)); */
}

.sticky {
  margin-left: 0vw;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: silver;

  /* background: linear-gradient(rgba(13, 187, 187, 0.658),rgba(86, 117, 117, 0.705), rgba(64, 115, 128, 0.164)); */
}

.blue {
  /* background-color: silver; */
  color: white;
}

.purple {
  /* background-color: darkcyan; */
  color: white;
}

@media (max-width: 750px) {
  .leftcard {
    width: 50%;
  }

  .rightcard {
    width: 100vw;
  }

  .parallax {
    margin-right: 0px;
  }

  .sticky {
    margin-left: 0px;
  }
}